<template>
    <b-row>
        <b-col cols="12">
            <div
                class="d-sm-flex justify-content-between align-items-center mb-4"
            >
                <feather
                    type="arrow-left-circle"
                    v-b-popover.hover.top="'Presione esc'"
                    role="button"
                    class="text-secondary"
                    size="2rem"
                    @click="back"
                ></feather>
                <div class="d-flex flex-row">
                    <b-button
                        variant="secondary"
                        :disabled="isLoading"
                        size="md"
                        class="mr-4"
                        v-b-popover.hover.top="'Presione esc'"
                        @click="back"
                    >
                        Cancelar
                    </b-button>

                    <b-button
                        variant="primary"
                        :disabled="isLoading || read_only"
                        size="md"
                        class="d-flex align-items-center"
                        v-b-popover.hover.top="
                            'Presione ctrl+shift+s o cmd+shift+s'
                        "
                        @click="save"
                    >
                        <feather
                            type="save"
                            size="1rem"
                            class="mr-2 text-blue-active"
                        />
                        <span
                            v-if="isLoading"
                            class="d-sm-flex align-items-center justify-content-center"
                        >
                            <b-spinner small></b-spinner>
                            <span class="ml-2">Guardando...</span>
                        </span>
                        <span v-else>Guardar</span>
                    </b-button>

                    <b-dropdown
                        v-if="$route.params.devolucion_id"
                        id="dropdown-divider"
                        text="Opciones"
                        size="md"
                        variant="link"
                        toggle-class="text-decoration-none"
                        no-caret
                        v-b-popover.hover.top="'Más opciones'"
                        class="ml-4"
                    >
                        <template #button-content>
                            <div
                                class="d-flex justify-content-center align-items-center"
                            >
                                <feather
                                    type="more-horizontal"
                                    size="1.5rem"
                                    class="text-secondary"
                                ></feather>
                            </div>
                        </template>
                        <b-dropdown-item-button
                            @click="confirmResendNc(devolucion_factura)"
                            :disabled="!((
                                devolucion_factura.notaCreditoE?.estadoDocumento !== undefined
                                &&
                                (
                                    devolucion_factura.notaCreditoE?.estadoDocumento.codigo === 'DEFAULT'
                                    ||
                                    devolucion_factura.notaCreditoE?.estadoDocumento.codigo === 'RECHAZADO'
                                )
                            )
                                ||
                                devolucion_factura.notaCreditoE?.reenviar)"
                        >
                            Reenviar documento electrónico
                        </b-dropdown-item-button>

                        <b-dropdown-item-button
                            @click="printDevolucionFactura(devolucion_factura.id)"
                            :disabled="((
                                devolucion_factura.notaCreditoE?.estadoDocumento !== undefined
                                &&
                                (
                                    devolucion_factura.notaCreditoE?.estadoDocumento.codigo === 'DEFAULT'
                                    ||
                                    devolucion_factura.notaCreditoE?.estadoDocumento.codigo === 'RECHAZADO'
                                )
                            )
                                ||
                                devolucion_factura.notaCreditoE?.reenviar)"
                        >
                            Imprimir
                        </b-dropdown-item-button>

                        <b-dropdown-item-button
                            @click="sendSingleFactura(devolucion_factura.id)"
                            :disabled="((
                                devolucion_factura.notaCreditoE?.estadoDocumento !== undefined
                                &&
                                (
                                    devolucion_factura.notaCreditoE?.estadoDocumento.codigo === 'DEFAULT'
                                    ||
                                    devolucion_factura.notaCreditoE?.estadoDocumento.codigo === 'RECHAZADO'
                                )
                            )
                                ||
                                devolucion_factura.notaCreditoE?.reenviar)"
                        >
                            Enviar por correo
                        </b-dropdown-item-button>
                    </b-dropdown>
                </div>
            </div>
        </b-col>
        <b-col
            cols="12"
            lg="12"
            class="d-flex align-items-stretch"
            style="min-height: 70vh"
        >
            <b-card class="mb-4 w-100">
                <div>
                    <b-form class="p-5">
                        <b-form v-hotkey="keymap">
                            <b-row class="mb-5">
                                <b-col cols="12" sm="6">
                                    <label>Número documento</label>
                                </b-col>
                                <b-col cols="12" sm="3">
                                    <b-form-input
                                        id="prefijo_devolucion"
                                        disabled
                                        v-model="
                                            $v.devolucion_factura.consecutivo
                                                .prefijo.$model
                                        "
                                        :state="
                                            validateStateDevolucion(
                                                'consecutivo'
                                            )
                                        "
                                    />
                                    <div
                                        class="invalid-field mt-1"
                                        v-if="
                                            !$v.devolucion_factura.consecutivo
                                                .required &&
                                            $v.devolucion_factura.consecutivo
                                                .$error
                                        "
                                    >
                                        Campo requerido
                                    </div>
                                </b-col>
                                <b-col cols="12" sm="3">
                                    <b-form-input
                                        id="numero_devolucion"
                                        type="number"
                                        class="form-control"
                                        :state="
                                            validateStateDevolucion('numero')
                                        "
                                        disabled
                                        v-model="devolucion_factura.numero"
                                    />
                                    <div
                                        class="invalid-field mt-1"
                                        v-if="
                                            !$v.devolucion_factura.numero
                                                .required &&
                                            $v.devolucion_factura.numero.$dirty
                                        "
                                    >
                                        Campo requerido
                                    </div>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="12" sm="4" class="mb-4">
                                    <label for="factura_venta_id"
                                        >Factura</label
                                    >
                                    <jautocomplete
                                        id="factura_venta_id"
                                        :items="facturas"
                                        item-text="descripcion"
                                        item-value="id"
                                        load-async
                                        open-on-focus
                                        placeholder="Número o prefijo de la factura"
                                        :custom-text-field="textFieldFactura"
                                        :disabled="read_only"
                                        @text="filterFacturas"
                                        @change="getFacturaWithDevoluciones"
                                        no-null
                                        :v-model="
                                            $v.devolucion_factura
                                                .factura_venta_id.$model
                                        "
                                    >
                                        <template v-slot:option="{ item }">
                                            <span>
                                                {{ item.consecutivo.prefijo }} -
                                                {{ item.numero }}</span
                                            >
                                        </template>
                                    </jautocomplete>
                                </b-col>
                                <b-col cols="12" md="8">
                                    <label for="cliente_id"
                                        >Establecimiento de comercio</label
                                    >
                                    <jautocomplete
                                        id="cliente_id"
                                        ref="cliente_id"
                                        :items="clientes"
                                        item-text="nombre_comercial"
                                        item-value="id"
                                        load-async
                                        open-on-focus
                                        @text="filterCliente"
                                        @change="assignCliente"
                                        :state="validateState('cliente_id')"
                                        :disabled="
                                            read_only ||
                                            devolucion_factura.factura_venta_id !==
                                                null
                                        "
                                        v-model="
                                            $v.factura_venta.cliente_id.$model
                                        "
                                        show-create-button
                                        :create-method="
                                            () => {
                                                $bvModal.show(
                                                    'create-establecimiento-comercio-modal'
                                                )
                                            }
                                        "
                                    >
                                        <template v-slot:option="{ item }">
                                            <span>
                                                {{
                                                    getLabelCliente(item)
                                                }}</span
                                            >
                                            <!-- <span>{{ item }}</span> -->
                                        </template>
                                    </jautocomplete>
                                    <div
                                        class="invalid-field mt-1"
                                        v-if="
                                            $v.factura_venta.cliente_id.$error
                                        "
                                    >
                                        Campo requerido
                                    </div>
                                </b-col>
                            </b-row>
                            <b-row class="mt-4">
                                <b-col cols="12" md="3">
                                    <label for="fecha">Fecha</label>
                                    <b-input-group>
                                        <b-form-input
                                            id="fecha"
                                            v-model="
                                                $v.devolucion_factura.fecha
                                                    .$model
                                            "
                                            type="text"
                                            placeholder="AAAA-MM-DD"
                                            :state="
                                                validateState('fecha_factura')
                                            "
                                            disabled
                                            autocomplete="null"
                                            @change="onChangeFechaFactura"
                                        ></b-form-input>
                                        <!-- @change="showLog" -->
                                        <b-input-group-append>
                                            <b-form-datepicker
                                                v-model="
                                                    $v.devolucion_factura.fecha
                                                        .$model
                                                "
                                                button-only
                                                right
                                                locale="en-US"
                                                :state="
                                                    validateStateDevolucion(
                                                        'fecha'
                                                    )
                                                "
                                                disabled
                                                @input="onChangeFechaFactura"
                                                aria-controls="fecha"
                                            ></b-form-datepicker>
                                        </b-input-group-append>
                                    </b-input-group>
                                    <b-form-invalid-feedback
                                        :state="
                                            $v.devolucion_factura.fecha
                                                .formatDate
                                        "
                                    >
                                        Formato de fecha inválido
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        :state="
                                            $v.devolucion_factura.fecha
                                                .compareDates
                                        "
                                    >
                                        La fecha no puede ser mayor al
                                        vencimiento
                                    </b-form-invalid-feedback>
                                </b-col>
                                <b-col cols="12" md="3">
                                    <label for="plazo">Plazo (días)</label>
                                    <b-form-input
                                        id="plazo"
                                        v-model="$v.plazo_vencimiento.$model"
                                        :disabled="read_only"
                                        @input="assignVencimiento"
                                        type="number"
                                        :state="
                                            validateStateSingle(
                                                'plazo_vencimiento'
                                            )
                                        "
                                        autocomplete="null"
                                    ></b-form-input>
                                    <b-form-invalid-feedback
                                        v-if="
                                            !$v.plazo_vencimiento
                                                .noNegativeNumbers
                                        "
                                    >
                                        El plazo no puede ser negativo
                                    </b-form-invalid-feedback>
                                </b-col>
                                <b-col cols="12" md="3">
                                    <label for="vencimiento">Vencimiento</label>
                                    <b-input-group>
                                        <b-form-input
                                            id="vencimiento"
                                            v-model="
                                                $v.devolucion_factura
                                                    .vencimiento.$model
                                            "
                                            type="text"
                                            placeholder="AAAA-MM-DD"
                                            disabled
                                            autocomplete="null"
                                        ></b-form-input>
                                    </b-input-group>
                                    <b-form-invalid-feedback
                                        :state="
                                            $v.devolucion_factura.vencimiento
                                                .formatDate
                                        "
                                    >
                                        Formato de fecha inválido
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        :state="
                                            $v.devolucion_factura.vencimiento
                                                .compareDates
                                        "
                                    >
                                        El vencimiento de la factura no puede
                                        ser mayor a la fecha
                                    </b-form-invalid-feedback>
                                </b-col>
                                <b-col cols="12" sm="3">
                                    <label for="vendedor_id">Vendedor</label>
                                    <jautocomplete
                                        id="vendedor_id"
                                        :items="vendedores"
                                        item-text="descripcion"
                                        item-value="id"
                                        open-on-focus
                                        v-model="
                                            $v.factura_venta.vendedor_id.$model
                                        "
                                        :disabled="
                                            vendedores.length === 0 ||
                                            read_only ||
                                            devolucion_factura.factura_venta_id !==
                                                null
                                        "
                                        show-create-button
                                        :create-method="
                                            () => {
                                                $bvModal.show(
                                                    'create-vendedor-modal'
                                                )
                                            }
                                        "
                                        :state="validateState('vendedor_id')"
                                    />
                                    <div
                                        class="invalid-field mt-1"
                                        v-if="
                                            $v.factura_venta.vendedor_id.$error
                                        "
                                    >
                                        Campo requerido
                                    </div>
                                </b-col>
                            </b-row>

                            <b-row class="mt-4">
                                <b-col cols="12">
                                    <label for="tipo_devolucion_id"
                                        >Tipo devolución</label
                                    >
                                    <jautocomplete
                                        id="tipo_devolucion_id"
                                        :items="tipos_devoluciones"
                                        item-text="descripcion"
                                        item-value="id"
                                        open-on-focus
                                        :disabled="read_only"
                                        v-model="
                                            $v.devolucion_factura
                                                .tipo_devolucion_id.$model
                                        "
                                        :state="
                                            validateStateDevolucion(
                                                'tipo_devolucion_id'
                                            )
                                        "
                                    />
                                    <div
                                        class="invalid-field mt-1"
                                        v-if="
                                            !$v.devolucion_factura
                                                .tipo_devolucion_id.required &&
                                            $v.devolucion_factura
                                                .tipo_devolucion_id.$error
                                        "
                                    >
                                        Campo requerido
                                    </div>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col cols="12" class="mt-4">
                                    <label for="nota">Nota</label>
                                    <b-input-group>
                                        <b-form-textarea
                                            id="nota"
                                            size="sm"
                                            :disabled="read_only"
                                            v-model="
                                                $v.devolucion_factura.nota
                                                    .$model
                                            "
                                            rows="1"
                                            placeholder="Alguna anotación?..."
                                        ></b-form-textarea>
                                    </b-input-group>
                                    <div
                                        class="length-field mt-1"
                                        v-if="
                                            !$v.devolucion_factura.nota
                                                .$maxLength
                                        "
                                    >
                                        ({{
                                            $v.devolucion_factura.nota.$model
                                                .length
                                        }}
                                        /
                                        {{
                                            $v.devolucion_factura.nota.$params
                                                .maxLength.max
                                        }})
                                    </div>
                                    <div
                                        class="invalid-field mt-1"
                                        v-if="
                                            !$v.devolucion_factura.nota
                                                .maxLength
                                        "
                                    >
                                        Este campo debe tener máximo
                                        {{
                                            $v.devolucion_factura.nota.$params
                                                .maxLength.max
                                        }}
                                        caracteres
                                    </div>
                                </b-col>
                            </b-row>
                            <!-- <b-row class="mt-4">
                                <b-col cols="12">
                                    <label for="nota">Nota</label>
                                    <b-input-group>
                                        <b-form-textarea
                                            id="nota"
                                            size="sm"
                                            :disabled="read_only"
                                            v-model="
                                                $v.factura_venta.nota_factura
                                                    .$model
                                            "
                                            rows="1"
                                            placeholder="Alguna anotación?..."
                                        ></b-form-textarea>
                                    </b-input-group>
                                </b-col>
                            </b-row> -->
                            <b-row class="mt-5">
                                <b-col cols="12">
                                    <div
                                        class="d-block d-sm-flex justify-content-between mb-5"
                                    >
                                        <label class="mb-4 font-weight-bold"
                                            >Productos</label
                                        >
                                        <div>
                                            <b-button
                                                variant="primary"
                                                size="md"
                                                class="d-flex align-items-center mt-4 mt-sm-0"
                                                @click="
                                                    $bvModal.show(
                                                        'modal-detalle-factura'
                                                    )
                                                "
                                            >
                                                <feather
                                                    type="eye"
                                                    size="1rem"
                                                    class="mr-2 text-blue-active"
                                                ></feather>
                                                Detalles de retenciones
                                            </b-button>
                                            <detalleFacturaVue
                                                :details="detalle_factura"
                                            />
                                        </div>
                                    </div>
                                    <b-row class="my-4">
                                        <b-col cols="12">
                                            <b-button
                                                variant="primary"
                                                size="md"
                                                class="d-flex align-items-center mt-4 mt-sm-0"
                                                @click="
                                                    $bvModal.show('modal-items')
                                                "
                                                ref="itemsButton"
                                                v-b-popover.hover.top="
                                                    'ctrl+b ó cmd+b'
                                                "
                                                :disabled="
                                                    read_only ||
                                                    devolucion_factura.factura_venta_id !==
                                                        null
                                                "
                                            >
                                                <feather
                                                    type="search"
                                                    size="1rem"
                                                    class="mr-2 text-blue-active"
                                                ></feather>
                                                Más items
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                    <!-- <div class="mt-4 mb-4">
                                        <b-input-group
                                            v-if="
                                                devolucion_factura.factura_venta_id
                                                    ? factura_venta.productos
                                                          .length >= 2
                                                    : factura_venta.productos
                                                          .length >= 3
                                            "
                                        >
                                            <b-form-input
                                                id="filter-input"
                                                type="text"
                                                placeholder="Filtrar de la lista de productos"
                                                class="bg-background-input"
                                                v-model="search"
                                                @input="searchData()"
                                            ></b-form-input>

                                            <b-input-group-append>
                                                <b-button
                                                    variant="background-input"
                                                    class="text-blue-active d-flex align-items-center"
                                                    @click="searchData()"
                                                >
                                                    <feather
                                                        type="search"
                                                        size="1.1rem"
                                                    ></feather>
                                                </b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </div> -->
                                    <b-table
                                        small
                                        hover
                                        responsive
                                        foot-clone
                                        striped
                                        :items="factura_venta_productosCopy"
                                        :fields="titulosProductos"
                                    >
                                        <template #cell(referencia)="data">
                                            <jautocomplete
                                                v-if="!data.item.item_id"
                                                :id="`referencia-${data.item.item_id}`"
                                                :items="productos"
                                                item-text="referencia"
                                                item-value="id"
                                                return-object
                                                load-async
                                                open-on-focus
                                                :disabled="read_only"
                                                @text="filterProductos"
                                                @change="insertProducto"
                                                show-create-button
                                                :create-method="
                                                    () => {
                                                        $bvModal.show(
                                                            'create-item-modal'
                                                        )
                                                    }
                                                "
                                            />
                                            <span v-else>{{ data.value }}</span>
                                        </template>
                                        <template #cell(descripcion)="data">
                                            <jautocomplete
                                                v-if="!data.item.item_id"
                                                :id="`producto-${data.item.item_id}`"
                                                :items="productos"
                                                item-text="descripcion"
                                                item-value="id"
                                                return-object
                                                load-async
                                                open-on-focus
                                                :disabled="read_only"
                                                @text="filterProductos"
                                                @change="insertProducto"
                                                show-create-button
                                                :create-method="
                                                    () => {
                                                        $bvModal.show(
                                                            'create-item-modal'
                                                        )
                                                    }
                                                "
                                            >
                                                <template
                                                    v-slot:option="{ item }"
                                                >
                                                    <span>
                                                        {{
                                                            getLabelItem(item)
                                                        }}</span
                                                    >
                                                </template>
                                            </jautocomplete>
                                            <span v-else>{{ data.value }}</span>
                                            <!-- <b-form-input
                                                v-if="
                                                    data.item.item_id !==
                                                        null &&
                                                    data.item.item_id !==
                                                        undefined
                                                "
                                                v-model="
                                                    $v
                                                        .factura_venta_productosCopy
                                                        .$each[data.index]
                                                        .descripcion.$model
                                                "
                                                type="text"
                                                :id="`producto-${data.item.item_id}`"
                                                :disabled="
                                                    read_only ||
                                                    !data.item.item_id
                                                "
                                                :state="
                                                    validateStateProducts(
                                                        data.index,
                                                        'descripcion'
                                                    )
                                                "
                                            ></b-form-input>
                                            <span v-else>{{ data.value }}</span>
                                            <b-form-invalid-feedback
                                                v-if="
                                                    !$v
                                                        .factura_venta_productosCopy
                                                        .$each[data.index]
                                                        .descripcion.required
                                                "
                                            >
                                                Campo requerido
                                            </b-form-invalid-feedback> -->
                                        </template>
                                        <template
                                            #cell(precio_base_venta)="data"
                                        >
                                            <currency-input
                                                v-if="
                                                    devolucion_factura.factura_venta_id ===
                                                        null &&
                                                    data.item.item_id !== null
                                                "
                                                :id="`precio-base-venta-producto-${data.item.item_id}`"
                                                :disabled="
                                                    read_only ||
                                                    !data.item.item_id
                                                "
                                                v-model.number="
                                                    $v.factura_venta.productos
                                                        .$each[data.index]
                                                        .precio_base_venta
                                                        .$model
                                                "
                                                @focus="onFocused"
                                                :state="
                                                    validateStateProducts(
                                                        data.index,
                                                        'precio_base_venta'
                                                    )
                                                "
                                            >
                                            </currency-input>
                                            <span v-else>{{
                                                currencyFormat(
                                                    $v
                                                        .factura_venta_productosCopy
                                                        .$each[data.index]
                                                        .precio_base_venta
                                                        .$model
                                                )
                                            }}</span>
                                        </template>
                                        <template #cell(valor)="data">
                                            {{
                                                currencyFormat(calcValor(data).toFixed(2))
                                            }}
                                        </template>
                                        <template #cell(cantidad)="data">
                                            <span v-if="data.item.item_id">
                                                <b-form-input
                                                    :id="`cantidad-producto-${data.item.item_id}`"
                                                    :disabled="
                                                        read_only ||
                                                        !data.item.item_id
                                                    "
                                                    @focus="onFocused"
                                                    v-model="
                                                        $v
                                                            .factura_venta_productosCopy
                                                            .$each[data.index]
                                                            .cantidad.$model
                                                    "
                                                    type="number"
                                                    :state="
                                                        validateStateProducts(
                                                            data.index,
                                                            'cantidad'
                                                        )
                                                    "
                                                    autocomplete="null"
                                                ></b-form-input>
                                                <b-form-invalid-feedback
                                                    v-if="
                                                        !$v
                                                            .factura_venta_productosCopy
                                                            .$each[data.index]
                                                            .cantidad.required
                                                    "
                                                >
                                                    Campo requerido
                                                </b-form-invalid-feedback>
                                                <b-form-invalid-feedback
                                                    v-if="
                                                        !$v
                                                            .factura_venta_productosCopy
                                                            .$each[data.index]
                                                            .cantidad.maxLength
                                                    "
                                                >
                                                    Máximo
                                                    {{
                                                        $v
                                                            .factura_venta_productosCopy
                                                            .$each[data.index]
                                                            .cantidad.$params
                                                            .maxLength.max
                                                    }}
                                                    caracteres
                                                </b-form-invalid-feedback>
                                            </span>
                                            <span v-else>{{
                                                $v.factura_venta_productosCopy
                                                    .$each[data.index].cantidad
                                                    .$model
                                            }}</span>
                                        </template>
                                        <!-- <template
                                            #cell(cantidad_devuelta)="data"
                                        >
                                            <span v-if="data.item.item_id">
                                                <b-form-input
                                                    :id="`cantidad-devuelta-producto-${data.item.item_id}`"
                                                    :disabled="
                                                        read_only ||
                                                        !data.item.item_id
                                                    "
                                                    @focus="onFocused"
                                                    v-model="
                                                        $v
                                                            .factura_venta_productosCopy
                                                            .$each[data.index]
                                                            .cantidad_devuelta
                                                            .$model
                                                    "
                                                    @input="
                                                        ($evt) => {
                                                            const value =
                                                                Number($evt)
                                                            if (
                                                                value >
                                                                data.item
                                                                    .cantidad
                                                            ) {
                                                                $v.factura_venta_productosCopy.$each[
                                                                    data.index
                                                                ].cantidad_devuelta.$model =
                                                                    data.item.cantidad
                                                            }
                                                        }
                                                    "
                                                    type="number"
                                                    :state="
                                                        validateStateProducts(
                                                            data.index,
                                                            'cantidad_devuelta'
                                                        )
                                                    "
                                                    autocomplete="null"
                                                ></b-form-input>
                                                <b-form-invalid-feedback
                                                    v-if="
                                                        !$v
                                                            .factura_venta_productosCopy
                                                            .$each[data.index]
                                                            .cantidad_devuelta
                                                            .required
                                                    "
                                                >
                                                    Campo requerido
                                                </b-form-invalid-feedback>
                                                <b-form-invalid-feedback
                                                    v-if="
                                                        !$v
                                                            .factura_venta_productosCopy
                                                            .$each[data.index]
                                                            .cantidad_devuelta
                                                            .maxLength
                                                    "
                                                >
                                                    Máximo
                                                    {{
                                                        $v
                                                            .factura_venta_productosCopy
                                                            .$each[data.index]
                                                            .cantidad_devuelta
                                                            .$params.maxLength
                                                            .max
                                                    }}
                                                    caracteres
                                                </b-form-invalid-feedback>
                                            </span>
                                            <span v-else>{{
                                                $v.factura_venta_productosCopy
                                                    .$each[data.index]
                                                    .cantidad_devuelta.$model
                                            }}</span>
                                        </template> -->

                                        <template
                                            #cell(porcentaje_descuento)="data"
                                        >
                                            <span>{{
                                                $v.factura_venta_productosCopy
                                                    .$each[data.index]
                                                    .porcentaje_descuento.$model
                                            }}</span>
                                        </template>

                                        <template #cell(total_iva)="data">
                                            {{
                                                data.item.tarifa_iva.porcentaje
                                            }}%
                                        </template>

                                        <template
                                            #cell(total_impoconsumo)="data"
                                        >
                                            {{
                                                currencyFormat(
                                                    calcTotalImpoconsumo(data).toFixed(2)
                                                )
                                            }}
                                        </template>

                                        <template #cell(total)="data">
                                            {{
                                                currencyFormat(calcTotal(data).toFixed(2))
                                            }}
                                        </template>

                                        <template #cell(acciones)="row">
                                            <feather
                                                v-if="
                                                    read_only ||
                                                    !row.item.item_id ||
                                                    devolucion_factura.factura_venta_id
                                                "
                                                v-b-popover.hover.top="
                                                    'Eliminar producto'
                                                "
                                                type="trash-2"
                                                class="text-secondary"
                                                size="1.3rem"
                                            />

                                            <feather
                                                v-else
                                                v-b-popover.hover.top="
                                                    'Eliminar producto'
                                                "
                                                type="trash-2"
                                                class="text-error"
                                                size="1.3rem"
                                                role="button"
                                                @click="confirmRemoveItem(row)"
                                            />

                                            <feather
                                                :type="
                                                    row.detailsShowing
                                                        ? 'arrow-up-circle'
                                                        : 'arrow-down-circle'
                                                "
                                                v-b-popover.hover.top="
                                                    row.detailsShowing
                                                        ? 'Ocultar detalles'
                                                        : 'Ver detalles'
                                                "
                                                @click="
                                                    ($evt) => {
                                                        row.toggleDetails($evt)
                                                        setBodegaAndLote(
                                                            row.item
                                                        )
                                                    }
                                                "
                                                size="1.3rem"
                                                role="button"
                                                class="text-secondary ml-2"
                                            />
                                        </template>

                                        <template #foot()="data">
                                            <div
                                                v-if="
                                                    data.field.key ===
                                                    'referencia'
                                                "
                                            >
                                                <b>Subtotal</b>
                                                <div>
                                                    {{
                                                        currencyFormat(
                                                            totalesFacturas.subtotal_neto.toFixed(2)
                                                        )
                                                    }}
                                                </div>
                                            </div>
                                            <div
                                                v-if="
                                                    data.field.key ===
                                                    'descripcion'
                                                "
                                            >
                                                <b>Descuento</b>
                                                <div>
                                                    {{
                                                        currencyFormat(
                                                            totalesFacturas.descuento.toFixed(2)
                                                        )
                                                    }}
                                                </div>
                                            </div>
                                            <div
                                                v-if="
                                                    data.field.key === 'valor'
                                                "
                                            >
                                                <b>Iva</b>
                                                <div>
                                                    {{
                                                        currencyFormat(
                                                            totalesFacturas.total_iva.toFixed(2)
                                                        )
                                                    }}
                                                </div>
                                            </div>
                                            <div
                                                v-if="
                                                    data.field.key ===
                                                    'cantidad'
                                                "
                                            >
                                                <b>Impoconsumo</b>
                                                <div>
                                                    {{
                                                        currencyFormat(
                                                            totalesFacturas.total_ipoconsumo.toFixed(2)
                                                        )
                                                    }}
                                                </div>
                                            </div>
                                            <div
                                                v-if="
                                                    data.field.key ===
                                                    'precio_base_venta'
                                                "
                                            >
                                                <b>Retefuente</b>
                                                <div>
                                                    {{
                                                        currencyFormat(
                                                            total_retefuente.toFixed(2)
                                                        )
                                                    }}
                                                </div>
                                            </div>
                                            <!-- <div
                                                v-if="
                                                    data.field.key ===
                                                    'cantidad_devuelta'
                                                "
                                            >
                                                <b>Reteiva</b>
                                                <div>
                                                    {{
                                                        currencyFormat(
                                                            total_reteiva
                                                        )
                                                    }}
                                                </div>
                                            </div> -->
                                            <div
                                                v-if="
                                                    data.field.key ===
                                                    'acciones'
                                                "
                                            >
                                                <b>Total</b>
                                                <div>
                                                    {{
                                                        currencyFormat(
                                                            granTotal.toFixed(2)
                                                        )
                                                    }}
                                                </div>
                                            </div>
                                        </template>

                                        <template #row-details="row">
                                            <b-card>
                                                <!-- {{ row.item }} -->
                                                <b-row class="pa-2">
                                                    <b-col cols="2">
                                                        <b>Descuento</b>
                                                        <div>
                                                            {{
                                                                currencyFormat(
                                                                    row.item
                                                                        .descuento
                                                                )
                                                            }}
                                                        </div>
                                                    </b-col>
                                                    <b-col cols="2">
                                                        <label for="bodega_id"
                                                            >Bodega</label
                                                        >
                                                        <!-- <jautocomplete
                                                            id="bodega_id"
                                                            :items="bodegas"
                                                            item-text="descripcion"
                                                            item-value="id"
                                                            open-on-focus
                                                            v-model="
                                                                row.item
                                                                    .bodega_id
                                                            "
                                                            disabled
                                                        /> -->
                                                        <jautocomplete
                                                            id="bodega_id"
                                                            :items="bodegas"
                                                            item-text="descripcion"
                                                            item-value="id"
                                                            open-on-focus
                                                            v-model="
                                                                row.item
                                                                    .bodega_id
                                                            "
                                                            :disabled="
                                                                read_only ||
                                                                !row.item
                                                                    .item_id ||
                                                                ($v
                                                                    .devolucion_factura
                                                                    .factura_venta_id
                                                                    .$model !==
                                                                    null)
                                                                || !permisos.includes('devolucion-factura-modificar-bodega')
                                                            "
                                                        />
                                                        <!-- <div>
                                                            {{
                                                                bodegas.find(
                                                                    (b) =>
                                                                        b.id ===
                                                                        row.item
                                                                            .bodega_id
                                                                )?.descripcion
                                                            }}
                                                        </div> -->
                                                    </b-col>
                                                    <b-col cols="2">
                                                        <label
                                                            for="lote_inventario_id"
                                                            >Lote de
                                                            inventario</label
                                                        >
                                                        <jautocomplete
                                                            id="lote_inventario_id"
                                                            :items="
                                                                lotes_inventario
                                                            "
                                                            item-text="nombre"
                                                            item-value="id"
                                                            open-on-focus
                                                            v-model="
                                                                row.item
                                                                    .lote_inventario_id
                                                            "
                                                            :disabled="
                                                                read_only ||
                                                                !row.item
                                                                    .item_id ||
                                                                ($v
                                                                    .devolucion_factura
                                                                    .factura_venta_id
                                                                    .$model !==
                                                                    null)
                                                                || !permisos.includes('devolucion-factura-modificar-lote')
                                                            "
                                                        />

                                                    </b-col>

                                                    <b-col cols="2">
                                                        <label
                                                            for="tercero_id"
                                                            >Tercero</label
                                                        >
                                                        <jautocomplete
                                                            id="tercero_id"
                                                            :items="tercerosCopy"
                                                            item-value="id"
                                                            item-text="n_documento"
                                                            :custom-filter="filterTerceros"
                                                            open-on-focus
                                                            :custom-text-field="textFieldTercero"
                                                            v-model="row.item.tercero_id"
                                                            show-create-button
                                                            :disabled="read_only ||
                                                                !row.item.item_id ||
                                                                !permisos.includes('devolucion-factura-modificar-tercero')
                                                                "
                                                        >
                                                            <template v-slot:option="{ item }">
                                                                <div
                                                                    class="p-1 option"
                                                                    role="button"
                                                                >
                                                                    {{
                                                                        item.tipo_documento_id ===
                                                                        '31'
                                                                        ? item.razon_social
                                                                        : `${item.nombre1} ${item.apellido1}`
                                                                    }}
                                                                </div>
                                                            </template>
                                                        </jautocomplete>
                                                    </b-col>

                                                    <b-col cols="2">
                                                        <label
                                                            for="concepto_contable_id"
                                                            >Concepto contable</label
                                                        >
                                                        <jautocomplete
                                                            id="concepto_contable_id"
                                                            :items="conceptos_contables
                                                                    "
                                                            item-text="descripcion"
                                                            item-value="id"
                                                            open-on-focus
                                                            v-model="row.item
                                                                .concepto_contable_id
                                                                "
                                                            :disabled="read_only ||
                                                                !row.item
                                                                    .item_id || !permisos.includes('devolucion-factura-modificar-concepto-contable')
                                                                "
                                                        />
                                                    </b-col>
                                                </b-row>
                                            </b-card>
                                        </template>
                                    </b-table>
                                    <div
                                        class="invalid-field mt-1"
                                        v-if="productsLengthError"
                                    >
                                        La lista de productos debe tener al
                                        menos
                                        {{
                                            $v.factura_venta.productos.$params
                                                .minLength.min
                                        }}
                                        producto
                                    </div>
                                </b-col>
                            </b-row>
                            <b-row class="mt-4" v-if="plazo_vencimiento == 0">
                                <!-- (devolucion_factura.factura_venta_id ===
                                        null ||
                                        devolucion_factura.factura_venta_id ===
                                            undefined ||
                                        devolucion_factura.factura_venta_id ===
                                            '') -->
                                <b-col cols="12">
                                    <label class="mb-4 font-weight-bold"
                                        >Formas de pago</label
                                    >
                                    <!-- {{ devolucion_factura.formas_pago }} -->
                                    <div
                                        v-for="(
                                            item, index
                                        ) in devolucion_factura.formas_pago"
                                        :key="index"
                                        class="my-3"
                                    >
                                        <b-row
                                            class="d-flex flex-row align-items-center"
                                        >
                                            <b-col cols="12" sm="5">
                                                <b-input-group>
                                                    <!-- @change="exisFormaPago" -->
                                                    <jautocomplete
                                                        :id="`forma_pago_${index}`"
                                                        :items="formas_pago"
                                                        item-value="id"
                                                        item-text="descripcion"
                                                        open-on-focus
                                                        v-model="
                                                            $v
                                                                .devolucion_factura
                                                                .formas_pago
                                                                .$each[index]
                                                                .forma_pago_id
                                                                .$model
                                                        "
                                                        :state="
                                                            validateStateFormasPago(
                                                                index,
                                                                'forma_pago_id'
                                                            )
                                                        "
                                                        :disabled="
                                                            read_only ||
                                                            devolucion_factura.factura_venta_id !==
                                                                null
                                                        "
                                                    />
                                                    <div
                                                        class="invalid-field mt-1"
                                                        v-if="
                                                            !$v
                                                                .devolucion_factura
                                                                .formas_pago
                                                                .$each[index]
                                                                .forma_pago_id
                                                                .required &&
                                                            $v
                                                                .devolucion_factura
                                                                .formas_pago
                                                                .$each[index]
                                                                .forma_pago_id
                                                                .$error
                                                        "
                                                    >
                                                        Campo requerido
                                                    </div>
                                                    <div
                                                        class="invalid-field mt-1"
                                                        v-if="
                                                            !$v
                                                                .devolucion_factura
                                                                .formas_pago
                                                                .$each[index]
                                                                .forma_pago_id
                                                                .noRepeat
                                                        "
                                                    >
                                                        No se admiten duplicados
                                                    </div>
                                                </b-input-group>
                                            </b-col>
                                            <b-col cols="12" sm="5">
                                                <currencyInput
                                                    v-model="
                                                        $v.devolucion_factura
                                                            .formas_pago.$each[
                                                            index
                                                        ].value.$model
                                                    "
                                                    :state="
                                                        validateStateFormasPago(
                                                            index,
                                                            'value'
                                                        )
                                                    "
                                                    :disabled="
                                                        read_only ||
                                                        devolucion_factura.factura_venta_id !==
                                                            null
                                                    "
                                                />
                                                <div
                                                    class="invalid-field mt-1"
                                                    v-if="
                                                        !$v.devolucion_factura
                                                            .formas_pago.$each[
                                                            index
                                                        ].value.minValue &&
                                                        $v.devolucion_factura
                                                            .formas_pago.$each[
                                                            index
                                                        ].value.$error
                                                    "
                                                >
                                                    Campo requerido
                                                </div>
                                            </b-col>
                                            <b-col cols="12" sm="2">
                                                <feather
                                                    type="plus"
                                                    role="button"
                                                    v-b-popover.hover.top="
                                                        'Agregar'
                                                    "
                                                    @click="
                                                        read_only ||
                                                        devolucion_factura.factura_venta_id !==
                                                            null
                                                            ? (() => {})()
                                                            : addFormaPago()
                                                    "
                                                ></feather>
                                                <feather
                                                    v-if="
                                                        devolucion_factura
                                                            .formas_pago
                                                            .length > 1
                                                    "
                                                    type="trash-2"
                                                    role="button"
                                                    class="ml-2"
                                                    v-b-popover.hover.top="
                                                        'Eliminar'
                                                    "
                                                    @click="
                                                        read_only
                                                            ? (() => {})()
                                                            : deleteFormaPago(
                                                                  index
                                                              )
                                                    "
                                                ></feather>
                                            </b-col>
                                        </b-row>
                                    </div>
                                </b-col>
                                <b-col cols="12">
                                    <label class="mb-4 font-weight-bold"
                                        >Cambio:</label
                                    >
                                    {{ currencyFormat(cambio) }}
                                </b-col>
                            </b-row>
                            <!-- <div v-if="$v.factura_venta.formas_pago.required">
                                Requerido
                            </div> -->
                        </b-form>
                    </b-form>
                    <prefijoModal
                        :consecutivos="consecutivos"
                        @selectedConsecutivo="setConsecutivo"
                    />
                    <!-- getNumeroFactura(consecutivo.id) -->
                </div>
            </b-card>
        </b-col>
        <b-col cols="12">
            <div class="float-right">
                <div class="d-flex flex-row">
                    <b-button
                        variant="secondary"
                        :disabled="isLoading"
                        size="md"
                        class="mr-4"
                        v-b-popover.hover.top="'Presione esc'"
                        @click="back"
                    >
                        Cancelar
                    </b-button>

                    <b-button
                        variant="primary"
                        :disabled="isLoading || read_only"
                        size="md"
                        class="d-flex align-items-center"
                        v-b-popover.hover.top="
                            'Presione ctrl+shift+s o cmd+shift+s'
                        "
                        @click="save"
                    >
                        <feather
                            type="save"
                            size="1rem"
                            class="mr-2 text-blue-active"
                        />
                        <span
                            v-if="isLoading"
                            class="d-sm-flex align-items-center justify-content-center"
                        >
                            <b-spinner small></b-spinner>
                            <span class="ml-2">Guardando...</span>
                        </span>
                        <span v-else>Guardar</span>
                    </b-button>

                    <b-dropdown
                        v-if="$route.params.devolucion_id"
                        id="dropdown-divider"
                        text="Opciones"
                        size="md"
                        variant="link"
                        toggle-class="text-decoration-none"
                        no-caret
                        v-b-popover.hover.top="'Más opciones'"
                        class="ml-4"
                    >
                        <template #button-content>
                            <div
                                class="d-flex justify-content-center align-items-center"
                            >
                                <feather
                                    type="more-horizontal"
                                    size="1.5rem"
                                    class="text-secondary"
                                ></feather>
                            </div>
                        </template>
                        <b-dropdown-item-button
                            @click="confirmResendNc(devolucion_factura)"
                            :disabled="!((
                                devolucion_factura.notaCreditoE?.estadoDocumento !== undefined
                                &&
                                (
                                    devolucion_factura.notaCreditoE?.estadoDocumento.codigo === 'DEFAULT'
                                    ||
                                    devolucion_factura.notaCreditoE?.estadoDocumento.codigo === 'RECHAZADO'
                                )
                            )
                                ||
                                devolucion_factura.notaCreditoE?.reenviar)"
                        >
                            Reenviar documento electrónico
                        </b-dropdown-item-button>

                        <b-dropdown-item-button
                            @click="printDevolucionFactura(devolucion_factura.id)"
                            :disabled="((
                                devolucion_factura.notaCreditoE?.estadoDocumento !== undefined
                                &&
                                (
                                    devolucion_factura.notaCreditoE?.estadoDocumento.codigo === 'DEFAULT'
                                    ||
                                    devolucion_factura.notaCreditoE?.estadoDocumento.codigo === 'RECHAZADO'
                                )
                            )
                                ||
                                devolucion_factura.notaCreditoE?.reenviar)"
                        >
                            Imprimir
                        </b-dropdown-item-button>

                        <b-dropdown-item-button
                            @click="sendSingleFactura(devolucion_factura.id)"
                            :disabled="((
                                devolucion_factura.notaCreditoE?.estadoDocumento !== undefined
                                &&
                                (
                                    devolucion_factura.notaCreditoE?.estadoDocumento.codigo === 'DEFAULT'
                                    ||
                                    devolucion_factura.notaCreditoE?.estadoDocumento.codigo === 'RECHAZADO'
                                )
                            )
                                ||
                                devolucion_factura.notaCreditoE?.reenviar)"
                        >
                            Enviar por correo
                        </b-dropdown-item-button>
                    </b-dropdown>
                </div>
            </div>
        </b-col>
        <crearVendedoresModalVue @newVendedor="setNewVendedor" />
        <crearEstablecimientoComercioModalVue
            @newEstablecimientoComercio="setNewEstablecimientoComercio"
        />
        <crearItemVue @newItem="setNewItem" />
        <modalItem @selectedItem="insertProducto" />
        <emailModal
            :id="'email-modal-single'"
            :sender="configEmail"
            :modulo="firstToUpperString(moduleName)"
            :fieldsAvailables="fieldsAvailables"
            :url="'administracion/devolucion-factura/enviar-mail'"
            :alternativeOpc="'FIND_BY_ID'"
            :extraParamsDatajson="singleEmailParams"
        />
    </b-row>
</template>
<script>
import services from '@/boot/axios'
import { validationMixin } from 'vuelidate'
import {
    required,
    maxLength,
    minLength,
    requiredIf
} from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import { compareDates, dateNow, daysLefts, dateAddDays } from '@/utils/date'
import jautocomplete from '@/components/shared/autocomplete/jautocomplete'
import {
    addListenerCommands,
    removeListenerCommands,
    goToNextField,
    onFocused,
    currencyFormat,
    compareObjects,
    findObjectInArray,
    firstToUpperString
} from '@/utils/others'
import { filterTerceros } from '@/utils/filters'
import { calcRetefuente } from '@/utils/facturaVenta'
import prefijoModal from '@/components/shared/prefijo-modal/prefijoModal'
import detalleFacturaVue from '@/components/shared/detalle-factura/detalleFactura.vue'
import currencyInput from '@/components/shared/currency-input/currency-input'
import print from 'print-js'
import crearVendedoresModalVue from '@/views/administrativo/configuracion/vendedores/crearVendedoresModal.vue'
import crearEstablecimientoComercioModalVue from '../../configuracion/establecimientos-comercio/crearEstablecimientoComercioModal.vue'
import crearItemVue from '../../inventario/items/crearItemModal.vue'
import modalItem from '@/components/shared/modal-items/modal-items.vue'
import emailModal from '@/components/shared/email/email'

const { API } = services

const defaultFacturaVenta = () =>
    JSON.parse(
        JSON.stringify({
            nota_factura: '',
            numero_factura: '',
            fecha_factura: dateNow(),
            vencimiento: dateNow(),
            cliente_id: null,
            gran_total: 0,
            subtotal: 0,
            total_reteiva: 0,
            total_descuento: 0,
            total_ica: 0,
            total_ipoconsumo: 0,
            total_iva: 0,
            total_retefuente: 0,
            consecutivo_factura: { prefijo: '' },
            tipo_documento_id: 2,
            estado_documento_id: null,
            subtotal_neto: 0,
            tercero_id: null,
            usuario_id: null,
            vendedor_id: null,
            sede_id: null,
            empresa_id: null,
            configuracion_impuestos: {},
            formas_pago: [],
            productos: [],
            cambio: 0
        })
    )

const defaultDevolucion = () =>
    JSON.parse(
        JSON.stringify({
            tipo_devolucion_id: null,
            factura_venta_id: null,
            consecutivo: { prefijo: '' },
            numero: '',
            fecha: dateNow(),
            nota: '',
            vencimiento: dateNow(),
            formas_pago: []
        })
    )

const defaultProducto = () =>
    JSON.parse(
        JSON.stringify({
            descripcion: '',
            cantidad: 0,
            // cantidad_devuelta: 0,
            descuento: 0,
            ipoconsumo: 0,
            porcentaje_descuento: 0,
            precio: 0,
            retefuente: 0,
            precio_base_venta: 0,
            valor: 0,
            valor_ica: 0,
            valor_iva: 0,
            subtotal: 0,
            subtotal_neto: 0,
            total: 0,
            cliente_id: null,
            bodega_id: null,
            lote_inventario_id: null,
            concepto_contable_id: null,
            tercero_id: null,
            item_id: null,
            factura_item_id: null,
            _showDetails: false
        })
    )

const defaultFormaPago = () => JSON.parse(JSON.stringify({
    forma_pago_id: null,
    value: 0
}))

export default {
    name: 'CrearDevolucionFactura',
    mixins: [validationMixin],
    components: {
        jautocomplete,
        prefijoModal,
        detalleFacturaVue,
        currencyInput,
        crearVendedoresModalVue,
        crearEstablecimientoComercioModalVue,
        crearItemVue,
        modalItem,
        emailModal
    },
    data: () => ({
        conceptos_contables: [],
        configEmail: null,
        moduleName: 'devolucion_factura',
        fieldsAvailables: {},
        singleEmailParams: {},
        terceros: [],
        bodegas: [],
        lotes_inventario: [],
        tercerosCopy: [],
        productos: [],
        vendedores: [],
        tipos_devoluciones: [],
        plazo_vencimiento: null,
        productsLengthError: null,
        detalle_factura: {},
        facturas: [],
        retencionBaseData: {
            tercero: {
                declarante: false,
                gran_contribuyente: false,
                agente_retencion_fuente: false,
                regimen_simple_tributacion: false
            },
            empresa: {
                agente_retencion_fuente: false,
                autoretenedor_fuente: false
            }
        },
        titulosProductos: [
            {
                key: 'referencia',
                label: 'Referencia',
                class: 'text-center',
                thStyle: { maxWidth: '10rem', textAlign: 'center' }
            },
            {
                key: 'descripcion',
                label: 'Producto',
                class: 'text-center',
                thStyle: { minWidth: '18rem', maxWidth: '18rem', textAlign: 'center' }
            },
            {
                key: 'cantidad',
                label: 'Cantidad',
                class: 'text-center',
                thStyle: { textAlign: 'center', maxWidth: '7rem' }
            },
            {
                key: 'precio_base_venta',
                label: 'Precio venta',
                class: 'text-center',
                thStyle: { textAlign: 'center', minWidth: '12rem' }
            },
            {
                key: 'valor',
                label: 'Valor',
                class: 'text-center',
                thStyle: { textAlign: 'center' }
            },
            {
                key: 'porcentaje_descuento',
                label: '% Descuento',
                class: 'text-center',
                thStyle: { minWidth: '7rem', textAlign: 'center' }
            },
            {
                key: 'total_iva',
                label: 'Iva',
                class: 'text-center',
                thStyle: { width: '10%', textAlign: 'center' }
            },
            {
                key: 'total_impoconsumo',
                label: 'Total impoconsumo',
                class: 'text-center',
                thStyle: { textAlign: 'center' }
            },
            {
                key: 'total',
                label: 'Total',
                class: 'text-center',
                thStyle: { width: '10%', textAlign: 'center' }
            },
            {
                key: 'acciones',
                label: 'Acciones',
                class: 'text-center',
                thStyle: { textAlign: 'center' }
            }
        ],
        total_retefuente: 0,
        total_reteiva: 0,
        clientes: [],
        consecutivos: [],
        factura_venta_copy: defaultFacturaVenta(),
        factura_venta: defaultFacturaVenta(),
        isLoading: false,
        isSaved: true,
        read_only: false,
        formas_pago: [],
        devolucion_factura: defaultDevolucion(),
        search: '',
        factura_venta_productosCopy: []
    }),
    watch: {
        factura_venta: {
            handler(newValue) {
                const productos = newValue.productos
                
                if (productos.length > 0) {
                    this.getTotalRetefuente(productos)
                }

                const { params: { devolucion_id } } = this.$route

                if (!devolucion_id) {
                    this.isSaved = compareObjects(this.factura_venta_copy, newValue)
                } else {
                    this.isSaved = true
                }
            },
            deep: true
        },
        factura_venta_productosCopy: {
            handler(newValue) {
                const productos = newValue

                this.factura_venta.productos = [...productos]
                this.factura_venta_copy.productos = [...productos]
                // this.factura_venta_copy = this.factura_venta
            },
            deep: true
        }
    },
    mounted() {
        this.goToNextField('factura_venta_id')
        this.getConceptosContables()
        this.getEmpresaEmails()
        this.getAvailableFields()
        addListenerCommands(this)
        this.getParams()
        // this.getVendedores()
        this.getTerceros()
        this.getConsecutivos()
        this.getTiposDevolucion()
        this.addBlankProduct()
        setTimeout(() => {
            this.getLotesInventario()
            this.getBodegas()
            this.getFormasPago()
        }, 500)
    },
    beforeDestroy() {
        removeListenerCommands(this)
    },
    beforeRouteLeave(to, from, next) {
        if (this.isSaved || this.read_only) {
            next()
        } else {
            this.$bvModal
                .msgBoxConfirm(
                    `
        Este formulario contiene información que no ha sido guardada,
        si continua con esta acción no se guardarán los cambios, desea continuar con esta acción?`,
                    {
                        title: 'Advertencia!',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: 'Continuar',
                        cancelTitle: 'Cancelar',
                        footerClass: 'p-2 d-flex justify-content-between',
                        hideHeaderClose: false,
                        centered: true,
                        headerBgVariant: 'danger',
                        headerClass: 'modal-header'
                    }
                )
                .then((value) => {
                    if (value) {
                        next()
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    },
    validations: {
        factura_venta: {
            fecha_factura: {
                required,
                compareDates: (d, a) => {
                    if (a.vencimiento) {
                        return compareDates(d, a.vencimiento)
                    }
                    return true
                },
                formatDate: (date) => {
                    if (date) {
                        const regExp =
                            /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/
                        const value = regExp.test(date)
                        return value
                    } else {
                        return true
                    }
                }
            },
            vencimiento: {
                required
            },
            cliente_id: {
                required
            },
            consecutivo_factura: {
                prefijo: {
                    required: requiredIf(function () {
                        // console.log(this.devolucion_factura)
                        return (
                            !this.devolucion_factura.factura_venta_id === null
                        )
                    })
                }
            },
            numero_factura: {
                required: requiredIf(function () {
                    // console.log(this.devolucion_factura)
                    return !this.devolucion_factura.factura_venta_id === null
                })
            },
            nota_factura: {},
            productos: {
                minLength: minLength(1),
                $each: {
                    precio_base_venta: {},
                    cantidad: {
                        required: (data, model) => {
                            if (
                                model.item_id !== null &&
                                model.item_id !== undefined &&
                                model.item_id !== ''
                            ) {
                                return data > 0
                            } else {
                                return true
                            }
                        },
                        maxLength: maxLength(10)
                    },
                    porcentaje_descuento: {
                        maxLength: maxLength(6)
                    },
                    bodega_id: {},
                    lote_inventario_id: {}
                }
            },
            vendedor_id: {
                required
            },
            formas_pago: {
                // required: requiredIf(function () {
                //     return (
                //         this.plazo_vencimiento == 0 &&
                //         this.factura_venta.formas_pago.length === 0
                //     )
                // }),
                // minLength: minLength(1),
                $each: {
                    forma_pago_id: {
                        required: requiredIf(function () {
                            return !this.plazo_vencimiento != 0
                        })
                    },
                    value: {
                        // required: requiredIf(function () {
                        //     return !this.plazo_vencimiento != 0
                        // }),
                        minValue: function (model) {
                            if (this.plazo_vencimiento != 0) {
                                return true
                            }
                            return !model == 0
                        }
                    }
                }
            }
        },
        factura_venta_productosCopy: {
            minLength: minLength(1),
            $each: {
                descripcion: {
                    required
                },
                precio_base_venta: {},
                cantidad: {
                    required: (data, model) => {
                        if (
                            model.item_id !== null &&
                            model.item_id !== undefined &&
                            model.item_id !== ''
                        ) {
                            return data > 0
                        } else {
                            return true
                        }
                    },
                    maxLength: maxLength(10)
                },
                porcentaje_descuento: {
                    maxLength: maxLength(6)
                },
                bodega_id: {},
                lote_inventario_id: {},
                tercero_id: {},
                concepto_contable_id: {}
            }
        },
        devolucion_factura: {
            tipo_devolucion_id: {
                required
            },
            factura_venta_id: {},
            nota: {
                maxLength: maxLength(500)
            },
            fecha: {
                required,
                compareDates: (d, a) => {
                    if (a.vencimiento) {
                        return compareDates(d, a.vencimiento)
                    }
                    return true
                },
                formatDate: (date) => {
                    if (date) {
                        const regExp =
                            /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/
                        const value = regExp.test(date)
                        return value
                    } else {
                        return true
                    }
                }
            },
            consecutivo: {
                prefijo: {
                    required
                }
            },
            numero: {
                required
            },
            vencimiento: {
                required
            },
            formas_pago: {
                $each: {
                    forma_pago_id: {
                        noRepeat: function (value) {
                            const filteredFp =
                                this.devolucion_factura.formas_pago.filter(
                                    (f) => f.forma_pago_id === value
                                )

                            if (filteredFp.length > 1) {
                                return false
                            } else {
                                return true
                            }
                        },
                        required: requiredIf(function () {
                            return (
                                this.plazo_vencimiento === 0 &&
                                (this.devolucion_factura.factura_venta_id ===
                                    null ||
                                    this.devolucion_factura.factura_venta_id ===
                                        undefined ||
                                    this.devolucion_factura.factura_venta_id ===
                                        '')
                            )
                        })
                    },
                    value: {
                        minValue: function (model) {
                            if (
                                this.plazo_vencimiento === 0 &&
                                (this.devolucion_factura.factura_venta_id ===
                                    null ||
                                    this.devolucion_factura.factura_venta_id ===
                                        undefined ||
                                    this.devolucion_factura.factura_venta_id ===
                                        '')
                            ) {
                                return true
                            }
                            return !model == 0
                        }
                    }
                }
            }
        },
        plazo_vencimiento: {
            noNegativeNumbers: (data) => {
                const number = Number(data)
                return number > -1
            }
        }
    },
    methods: {
        onFocused,
        currencyFormat,
        goToNextField,
        firstToUpperString,
        filterTerceros,
        searchData() {
            if (this.search) {
                // console.log(this.search)
                this.factura_venta_productosCopy =
                    this.factura_venta.productos.filter(
                        (p) =>
                            p.descripcion
                                .toLowerCase()
                                .includes(this.search.toLowerCase()) ||
                            p.referencia.includes(this.search)
                    )
            } else {
                this.factura_venta_productosCopy = JSON.parse(
                    JSON.stringify(this.factura_venta.productos)
                )
            }
        },
        setFactura(factura) {
            this.factura_venta_productosCopy = []
            const {
                fecha,
                nota,
                // id,
                zona,
                cliente,
                numero,
                sede_id,
                empresa_id,
                consecutivo,
                cliente_id,
                vencimiento,
                vendedor_id,
                vendedor,
                tercero,
                productos,
                formas_pago,
                total_ica,
                total_iva,
                tipo_documento_id,
                plazo,
                // configuracion_impuestos
            } = factura

            // let products_devolucion = productos.map(p => {
            //     const product = configuracion_impuestos.productos.find(fp => fp.item_id === p.item_id)

            //     return { ...p, factura_item_id: p.factura_item_id, factura_venta_id: p.factura_venta_id }
            // })

            let products_devolucion = productos

            const existCliente = findObjectInArray(cliente, this.clientes)

            if (!existCliente) {
                // console.log(cliente)
                this.clientes.push(cliente)
            }

            this.factura_venta_productosCopy = JSON.parse(
                JSON.stringify(products_devolucion)
            )

            // console.log(this.factura_venta_productosCopy)

            const devolucion = {
                fecha_factura: fecha,
                vencimiento,
                vendedor_id,
                vendedor,
                cliente_id,
                cliente,
                tercero,
                nota_factura: nota,
                numero_factura: numero,
                sede_id,
                empresa_id,
                consecutivo_factura: consecutivo,
                productos: products_devolucion,
                formas_pago,
                total_ica,
                total_iva,
                zona,
                tipo_documento_id
            }

            this.factura_venta = {
                ...this.factura_venta,
                ...devolucion
            }

            this.factura_venta_copy = {
                ...this.factura_venta,
                ...devolucion
            }

            setTimeout(() => {
                this.assignCliente(cliente_id, plazo, vendedor_id)
                this.getTotalRetefuente(productos)
                this.devolucion_factura.formas_pago = formas_pago
            }, 500)

            setTimeout(() => {
                formas_pago.forEach((forma, index) => {
                    const input_fp = document.getElementById(
                        `forma_pago_${index}`
                    )
                    input_fp.value = forma.forma_pago_descripcion
                })
            }, 500)
        },
        textFieldFactura(factura) {
            if (factura) {
                const {
                    numero,
                    consecutivo: { prefijo }
                } = factura
                return `${prefijo} - ${numero}`
            }
            return ''
        },
        getFacturasVenta(term) {
            API.POST({
                url: 'administracion/factura-venta/query',
                data: {
                    p_datajson: {
                        term,
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'GET_ALL_BY_NUMERO_PREFIJO',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        // console.log(dato)
                        this.facturas = dato.map((f) => ({
                            ...f,
                            consecutivo: {
                                ...f.consecutivo,
                                prefijo:
                                    f.consecutivo.prefijo === ''
                                        ? 'Sin prefijo'
                                        : f.consecutivo.prefijo
                            }
                        }))
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getTiposDevolucion() {
            API.POST({
                url: 'administracion/tipo-devolucion/query',
                data: {
                    p_datajson: {
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'GET_ALL',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.tipos_devoluciones = [...dato]
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        showLog(data) {
            console.log(data)
        },
        setNewItem(item) {
            this.productos.push(item)
            // console.log(item)
            this.insertProducto(item)
        },
        setNewEstablecimientoComercio(establecimiento) {
            // console.log(establecimiento)
            this.clientes.push(establecimiento)
            this.assignCliente(establecimiento.id)
        },
        setNewVendedor(vendedor) {
            this.vendedores.push(vendedor)
            this.factura_venta.vendedor_id = vendedor.id
        },
        setBodegaAndLote(item) {
            const { bodega_id, lote_inventario_id, concepto_contable_id, tercero_id } = item
            item.bodega_id = null
            item.lote_inventario_id = null
            item.concepto_contable_id = null
            item.tercero_id = null

            setTimeout(() => {
                // console.log(bodega_id, lote_inventario_id)
                item.bodega_id = bodega_id
                item.lote_inventario_id = lote_inventario_id
                item.concepto_contable_id = concepto_contable_id
                item.tercero_id = tercero_id
            }, 100)
        },
        addFormaPago() {
            this.devolucion_factura.formas_pago.push(defaultFormaPago())
        },
        deleteFormaPago(index) {
            this.devolucion_factura.formas_pago.splice(index, 1)
        },
        getConceptosContables() {
            API.POST({
                url: 'administracion/concepto-digitacion/all',
                data: {
                    empresaId: this.getUltimaEmpresa.id
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.conceptos_contables = dato
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                mensaje_exception ||
                                'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getFormasPago() {
            API.POST({
                url: 'administracion/forma-pago/query',
                data: {
                    p_datajson: {
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'GET_ALL',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    // console.log('a')
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        // console.log('fp')
                        this.formas_pago = dato
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        // getVendedores() {
        //     API.POST({
        //         url: 'administracion/vendedor/query',
        //         data: {
        //             p_datajson: {
        //                 empresa_id: this.getUltimaEmpresa.id
        //             },
        //             p_opc: 'GET_ALL',
        //             p_auditoriajson: this.auditoriajson
        //         }
        //     })
        //         .then((response) => {
        //             const {
        //                 data: {
        //                     dato,
        //                     status: { status, mensaje, mensaje_exception }
        //                 }
        //             } = response
        //             if (status === 'ok') {
        //                 this.vendedores = [...dato]
        //             } else {
        //                 this.$bvModal
        //                     .msgBoxOk(
        //                         mensaje ||
        //                             mensaje_exception ||
        //                             'Ha ocurrido un error!',
        //                         {
        //                             title: 'Error!',
        //                             size: 'sm',
        //                             buttonSize: 'sm',
        //                             okVariant: 'error',
        //                             okTitle: 'Aceptar',
        //                             cancelTitle: 'Cancelar',
        //                             footerClass: 'p-2',
        //                             hideHeaderClose: false,
        //                             centered: true,
        //                             headerBgVariant: 'error',
        //                             headerClass: 'modal-header'
        //                         }
        //                     )
        //                     .then(() => {
        //                         // console.log(value);
        //                     })
        //                     .catch((err) => {
        //                         console.log(err)
        //                     })
        //             }
        //         })
        //         .catch((error) => {
        //             const { message } = error
        //             this.$bvModal
        //                 .msgBoxOk(message || 'Ha ocurrido un error!', {
        //                     title: 'Error!',
        //                     size: 'sm',
        //                     buttonSize: 'sm',
        //                     okVariant: 'error',
        //                     okTitle: 'Aceptar',
        //                     cancelTitle: 'Cancelar',
        //                     footerClass: 'p-2',
        //                     hideHeaderClose: false,
        //                     centered: true,
        //                     headerBgVariant: 'error',
        //                     headerClass: 'modal-header'
        //                 })
        //                 .then(() => {
        //                     // console.log(value);
        //                 })
        //                 .catch((err) => {
        //                     console.log(err)
        //                 })
        //         })
        // },
        setConsecutivo(consecutivo) {
            this.devolucion_factura.consecutivo = consecutivo
            this.devolucion_factura.numero = consecutivo.secuencia

            setTimeout(() => {
                const ELEMENT = document.getElementById('factura_venta_id')
                ELEMENT.focus()
            }, 500)
        },
        checkPrefijo() {
            const isSelected =
                this.devolucion_factura.consecutivo?.prefijo !== '' &&
                this.devolucion_factura.consecutivo?.prefijo !== null
            if (!isSelected) {
                this.$bvModal.show('modal-prefijo')
            }
        },
        confirmRemoveItem(data) {
            const {
                item: { descripcion },
                index
            } = data

            this.$bvModal
                .msgBoxConfirm(
                    `Está seguro que desea eliminar el producto "${descripcion}" de la lista de productos de la factura de venta?`,
                    {
                        title: 'Advertencia!',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: 'Continuar',
                        cancelTitle: 'Cancelar',
                        footerClass: 'p-2 d-flex justify-content-between',
                        hideHeaderClose: false,
                        centered: true,
                        headerBgVariant: 'danger',
                        headerClass: 'modal-header'
                    }
                )
                .then((value) => {
                    if (value) {
                        this.removeItemFromFacturaVenta(index)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        removeItemFromFacturaVenta(index) {
            // this.factura_venta.productos.splice(index, 1)
            this.factura_venta_productosCopy.splice(index, 1)
        },
        calcTotalIva(data) {
            const result = Number(
                ((data.item.valor * data.item.cantidad -
                    // - data.item.cantidad_devuelta
                    (data.item.valor *
                        data.item.cantidad *
                        // - data.item.cantidad_devuelta
                        data.item.porcentaje_descuento) /
                        100) *
                    data.item.tarifa_iva.porcentaje) /
                    100
            )
            // this.factura_venta.productos[data.index].total_iva = result
            // this.factura_venta.productos[data.index].valor_iva = result
            this.factura_venta_productosCopy[data.index].total_iva = result
            this.factura_venta_productosCopy[data.index].valor_iva = result
            return result
        },
        calcDescuento(data) {
            const result = Number(
                data.item.cantidad *
                    data.item.valor *
                    (data.item.porcentaje_descuento / 100)
            )
            this.factura_venta_productosCopy[data.index].descuento = result
            return result
        },
        calcTotalImpoconsumo(data) {
            // se verifica si el impoconsumo es porecntaje (0) y se hace el cálculo
            // sino es porcentaje se devuelve el valor
            // console.log(data.item.tipo_ipoconsumo == 0 ? 'porcentaje' : 'vslor')
            const {
                tipo_ipoconsumo,
                valor_ipoconsumo,
                porcentaje_descuento,
                valor,
                cantidad
                // cantidad_devuelta
            } = data.item
            
            let impoconsumo

            if (tipo_ipoconsumo == 0) {
                const descuento = (valor * porcentaje_descuento) / 100
                const valorNeto = valor - descuento
                impoconsumo = (valorNeto * valor_ipoconsumo) / 100
                // console.log(impoconsumo)
            } else if (tipo_ipoconsumo == 1) {
                impoconsumo = valor_ipoconsumo
            } else {
                impoconsumo = 0
            }

            const result = Number(
                impoconsumo * cantidad
                // - cantidad_devuelta
            )

            // this.factura_venta.productos[data.index].total_ipoconsumo = result
            this.factura_venta_productosCopy[data.index].total_ipoconsumo =
                result
            return result
        },
        calcTotal(data) {
            const { cantidad, valor, total_iva, descuento } = data.item
            const subtotal = Number(cantidad * valor)
            const total = subtotal + total_iva - descuento

            this.factura_venta_productosCopy[data.index].total = total
            this.factura_venta_productosCopy[data.index].subtotal_neto =
                subtotal
            // this.factura_venta.productos[data.index].total = total
            // this.factura_venta.productos[data.index].subtotal_neto = subtotal
            return total

            // const { cantidad, valor, descuento /* cantidad_devuelta*/ } =
            //     data.item
            // const result = Math.round(cantidad /*- cantidad_devuelta*/ * valor)
            // const t = Math.round(
            //     cantidad /*- cantidad_devuelta*/ * valor - descuento
            // )
            // this.factura_venta.productos[data.index].total = result
            // this.factura_venta.productos[data.index].subtotal_neto = t
            // this.factura_venta_productosCopy[data.index].total = result
            // this.factura_venta_productosCopy[data.index].subtotal_neto = t
            // const descuento = this.factura_venta.productos[data.index].descuento
            // console.log(descuento)
            // this.factura_venta.productos[data.index].subtotal_producto =
            //     result - descuento
            // return result
        },
        calcValor(data) {
            const {
                index,
                item: {
                    precio_base_venta,
                    tarifa_iva,
                    tipo_ipoconsumo,
                    valor_ipoconsumo
                }
            } = data
            const porcentaje_iva = 1 + tarifa_iva.porcentaje / 100
            const valorNeto =
                tipo_ipoconsumo == 0 || tipo_ipoconsumo === 'NA'
                    ? precio_base_venta / porcentaje_iva
                    : (precio_base_venta - valor_ipoconsumo) / porcentaje_iva
            const result = Number(valorNeto)

            // this.factura_venta.productos[index].valor = result
            this.factura_venta_productosCopy[index].valor = result

            this.calcTotalIva(data)
            this.calcDescuento(data)

            return result
        },
        filterProductos(text) {
            if (text && text.length > 3) {
                this.getProductos({ term: text })
            }
        },
        filterFacturas(text) {
            if (text && text.length > 0) {
                // this.getProductos({ term: text })
                this.getFacturasVenta(text)
            }
        },
        filterCliente(text) {
            if (text && text.length > 3) {
                this.getClientes({ term: text })
            }
        },
        getNumeroFactura(id) {
            if (this.getUltimaEmpresa) {
                API.POST({
                    url: 'administracion/consecutivo/query',
                    data: {
                        p_datajson: {
                            id //id del consecutivo
                        },
                        p_opc: 'GET_NUMERO_SECUENCIA',
                        p_auditoriajson: this.auditoriajson
                    }
                })
                    .then((response) => {
                        const {
                            data: {
                                dato,
                                status: { status, mensaje, mensaje_exception }
                            }
                        } = response
                        if (status === 'ok') {
                            this.factura_venta.numero = dato.numero
                            // this.consecutivos = dato
                        } else {
                            this.$bvModal
                                .msgBoxOk(
                                    mensaje ||
                                        mensaje_exception ||
                                        'Ha ocurrido un error!',
                                    {
                                        title: 'Error!',
                                        size: 'sm',
                                        buttonSize: 'sm',
                                        okVariant: 'error',
                                        okTitle: 'Aceptar',
                                        cancelTitle: 'Cancelar',
                                        footerClass: 'p-2',
                                        hideHeaderClose: false,
                                        centered: true,
                                        headerBgVariant: 'error',
                                        headerClass: 'modal-header'
                                    }
                                )
                                .then(() => {
                                    // console.log(value);
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                    })
                    .catch((error) => {
                        const { message } = error
                        this.$bvModal
                            .msgBoxOk(message || 'Ha ocurrido un error!', {
                                title: 'Error!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'error',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'error',
                                headerClass: 'modal-header',
                                noStacking: true
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    })
            }
        },
        getLotesInventario() {
            API.POST({
                url: 'administracion/inventario/lote-inventario/query',
                data: {
                    p_datajson: {
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'GET_ALL',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.lotes_inventario = [...dato]
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getBodegas() {
            API.POST({
                url: 'administracion/inventario/bodega/query',
                data: {
                    p_datajson: {
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'GET_ALL',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.bodegas = [...dato]
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getClientes({ term }) {
            API.POST({
                url: 'general/establecimiento-comercio/query',
                data: {
                    p_datajson: {
                        term,
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'GET_ALL_CLIENTES',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        // console.log(dato)
                        this.clientes = dato
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getConsecutivos() {
            if (this.getUltimaEmpresa) {
                API.POST({
                    url: 'administracion/consecutivo/query',
                    data: {
                        p_datajson: {
                            clase_documento_id: 1,
                            empresa_id: this.getUltimaEmpresa.id
                        },
                        p_opc: 'GET_ALL_BY_CLASE_DOCUMENTO',
                        p_auditoriajson: this.auditoriajson
                    }
                })
                    .then((response) => {
                        const {
                            data: {
                                dato,
                                status: { status, mensaje, mensaje_exception }
                            }
                        } = response
                        if (status === 'ok') {
                            // console.log(dato)
                            this.consecutivos = dato.map((c) => {
                                return c.prefijo === ''
                                    ? {
                                          ...c,
                                          prefijo: 'Sin prefijo'
                                      }
                                    : c
                            })
                        } else {
                            this.$bvModal
                                .msgBoxOk(
                                    mensaje ||
                                        mensaje_exception ||
                                        'Ha ocurrido un error!',
                                    {
                                        title: 'Error!',
                                        size: 'sm',
                                        buttonSize: 'sm',
                                        okVariant: 'error',
                                        okTitle: 'Aceptar',
                                        cancelTitle: 'Cancelar',
                                        footerClass: 'p-2',
                                        hideHeaderClose: false,
                                        centered: true,
                                        headerBgVariant: 'error',
                                        headerClass: 'modal-header'
                                    }
                                )
                                .then(() => {
                                    // console.log(value);
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                    })
                    .catch((error) => {
                        const { message } = error
                        this.$bvModal
                            .msgBoxOk(message || 'Ha ocurrido un error!', {
                                title: 'Error!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'error',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'error',
                                headerClass: 'modal-header',
                                noStacking: true
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    })
            }
        },
        textFieldTercero(tercero) {
            return tercero.tipo_documento_id === '31'
                ? tercero.razon_social
                : `${tercero.nombre1} ${tercero.apellido1}`
        },
        getParams() {
            const { devolucion_id, factura_venta_id } = this.$route.params
            // console.log(devolucion_id)
            if (devolucion_id) {
                this.getClientes({ term: '' })
                this.getDevolucionFactura(devolucion_id)
            } else if (factura_venta_id) {
                this.getFacturasVenta('')
                this.getFacturaWithDevoluciones(factura_venta_id)
                this.checkPrefijo()
                this.addFormaPago()
            } else {
                this.checkPrefijo()
                this.addFormaPago()
                this.getClientes({ term: '' })
            }

            this.read_only = this.$route.meta.read_only
        },
        getFacturaWithDevoluciones(id) {
            API.POST({
                url: 'administracion/factura-venta/query',
                data: {
                    p_datajson: {
                        id,
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'FIND_BY_ID_WITH_DEVOLUCIONES',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    // console.log(dato);
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        // console.log(dato)
                        if (dato.devuelto) {
                            this.$bvModal
                                .msgBoxOk(
                                    'La factura ya ha sido devuelta en su totalidad',
                                    {
                                        title: 'Advertencia!',
                                        size: 'sm',
                                        buttonSize: 'sm',
                                        okVariant: 'warning',
                                        okTitle: 'Aceptar',
                                        cancelTitle: 'Cancelar',
                                        footerClass: 'p-2',
                                        hideHeaderClose: false,
                                        centered: true,
                                        headerBgVariant: 'warning',
                                        headerClass: 'modal-header'
                                    }
                                )
                                .then(() => {
                                    // console.log(value);
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        } else {
                            this.devolucion_factura = {
                                ...this.devolucion_factura,
                                factura_venta_id: dato.id
                            }
                            const facturaVentaIdInput =
                                document.getElementById('factura_venta_id')
                            // console.log(facturaVentaIdInput)
                            facturaVentaIdInput.value = `${
                                dato.consecutivo.prefijo === ''
                                    ? 'Sin prefijo'
                                    : dato.consecutivo.prefijo
                            } - ${dato.numero}`
                            this.setFactura(dato)
                        }

                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getDevolucionFactura(id) {
            // console.log('acá 2');
            API.POST({
                url: 'administracion/devolucion-factura/query',
                data: {
                    p_datajson: {
                        id
                    },
                    p_opc: 'FIND_BY_ID',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        // console.log(dato)
                        const {
                            fecha,
                            numero,
                            consecutivo,
                            nota,
                            factura_venta_id,
                            tipo_devolucion_id,
                            vencimiento,
                            cliente_id,
                            vendedor_id,
                            configuracion_impuestos,
                            formas_pago,
                            plazo,
                            notaCreditoE,
                            id
                        } = dato

                        this.plazo_vencimiento = plazo

                        this.devolucion_factura = {
                            fecha,
                            numero,
                            consecutivo,
                            nota,
                            formas_pago,
                            factura_venta_id:
                                factura_venta_id === 0
                                    ? null
                                    : factura_venta_id,
                            tipo_devolucion_id,
                            notaCreditoE,
                            id
                        }

                        // console.log(dato)

                        const facturaVentaIdInput =
                            document.getElementById('factura_venta_id')
                        facturaVentaIdInput.value = `${
                            dato.consecutivo_factura.prefijo
                                ? dato.consecutivo_factura.prefijo
                                : 'Sin prefijo'
                        } - ${dato.numero_factura}`

                        this.setFactura(dato)
                        // console.log(dato)
                        // const retrieveFacturaVenta = {
                        //     ...dato,
                        //     numero: dato.consecutivo.secuencia,
                        //     productos: dato.configuracion_impuestos.productos
                        // }
                        // this.factura_venta_copy = {
                        //     ...retrieveFacturaVenta
                        // }
                        // this.factura_venta = {
                        //     ...retrieveFacturaVenta
                        // }

                        const pc = daysLefts(fecha, vencimiento)
                        setTimeout(() => {
                            // console.log(cliente_id)
                            this.assignCliente(cliente_id, pc, vendedor_id)
                            this.getTotalRetefuente(
                                configuracion_impuestos.productos
                            )
                        }, 500)
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getTerceros() {
            if (this.getUltimaEmpresa) {
                API.POST({
                    url: 'general/tercero/query',
                    data: {
                        p_datajson: {
                            empresa_id: this.getUltimaEmpresa.id
                        },
                        p_opc: 'GET_ALL',
                        p_auditoriajson: this.auditoriajson
                    }
                })
                    .then((response) => {
                        const {
                            data: {
                                dato,
                                status: { status, mensaje, mensaje_exception }
                            }
                        } = response
                        if (status === 'ok') {
                            this.terceros = dato
                            this.tercerosCopy = dato
                        } else {
                            this.$bvModal
                                .msgBoxOk(
                                    mensaje ||
                                        mensaje_exception ||
                                        'Ha ocurrido un error!',
                                    {
                                        title: 'Error!',
                                        size: 'sm',
                                        buttonSize: 'sm',
                                        okVariant: 'error',
                                        okTitle: 'Aceptar',
                                        cancelTitle: 'Cancelar',
                                        footerClass: 'p-2',
                                        hideHeaderClose: false,
                                        centered: true,
                                        headerBgVariant: 'error',
                                        headerClass: 'modal-header'
                                    }
                                )
                                .then(() => {
                                    // console.log(value);
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                    })
                    .catch((error) => {
                        const { message } = error
                        this.$bvModal
                            .msgBoxOk(message || 'Ha ocurrido un error!', {
                                title: 'Error!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'error',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'error',
                                headerClass: 'modal-header',
                                noStacking: true
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    })
            }
        },
        addBlankProduct() {
            const producto = {
                ...defaultProducto(),
                cantidad: 0,
                referencia: '',
                descripcion: 'No seleccionado',
                precio_base_venta: 0,
                valor: 0,
                porcentaje_descuento: 0,
                descuento: 0,
                valor_iva: 0,
                total_iva: 0,
                tipo_ipoconsumo: 'NA',
                valor_ipoconsumo: 0,
                total_ipoconsumo: 0,
                retencion: null,
                bodega_id: 0,
                lote_inventario_id: 0,
                tarifa_iva: { porcentaje: 0 },
                item_id: null,
                total: 0
            }

            // this.factura_venta.productos = [
            //     producto,
            //     ...this.factura_venta.productos
            // ]
            this.factura_venta_productosCopy = [
                producto,
                ...this.factura_venta_productosCopy
            ]

            this.factura_venta.productos = this.factura_venta_productosCopy
            this.factura_venta_copy.productos = this.factura_venta_productosCopy
        },
        insertProducto(product) {
            // console.log(product)
            const {
                descripcion,
                precio_base_venta,
                id: item_id,
                referencia,
                tipo_ipoconsumo,
                valor_ipoconsumo,
                iva: tarifa_iva,
                retencion,
                bodega_id,
                lote_inventario_id,
                concepto_contable_id
            } = product
            // const exist = this.factura_venta.productos.find(
            //     (producto) => producto.item_id === item_id
            // )
            // if (!exist) {
            const producto = {
                ...defaultProducto(),
                referencia,
                descripcion,
                precio_base_venta,
                valor: 0,
                porcentaje_descuento: 0,
                descuento: 0,
                valor_iva: 0,
                total_iva: 0,
                tipo_ipoconsumo,
                valor_ipoconsumo,
                total_ipoconsumo: 0,
                retencion,
                bodega_id,
                lote_inventario_id,
                tarifa_iva,
                item_id,
                concepto_contable_id,
                tercero_id: this.factura_venta.tercero_id,
                total: 0
            }

            // this.factura_venta.productos = [
            //     ...this.factura_venta.productos,
            //     producto
            // ]
            const [emptyProduct, ...rest] = this.factura_venta_productosCopy

            this.factura_venta_productosCopy = [emptyProduct, producto, ...rest]
            // setTimeout(() => {
            //     const blank_referencia =
            //         document.getElementById(`referencia-null`)
            //     const blank_producto = document.getElementById(`producto-null`)
            //     blank_referencia.value = ''
            //     blank_producto.value = ''
            // }, 50)
            this.goToNextField(`cantidad-producto-${item_id}`)
            // this.$v.productos.$model.push(producto)
            // }
            // else {
            //     this.$notify({
            //         group: 'general',
            //         title: 'Producto existente',
            //         text: `${descripcion} ya ha sido registrado`,
            //         ignoreDuplicates: true,
            //         duration: 5000,
            //         type: 'error'
            //     })
            // }
        },
        getProductos({ term }) {
            API.POST({
                // url: 'administracion/inventario/item/query',
                url: 'administracion/inventario/inventario-saldo/query',
                data: {
                    p_datajson: {
                        term,
                        bodega_id: this.getUltimaSede.bodega_id || 0,
                        lote_inventario_id:
                            this.getUltimaSede.lote_inventario_id || 0,
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'FILTER',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        // this.productos = dato
                        this.productos = dato.content.map(
                            ({
                                lote_inventario_id,
                                bodega_id,
                                lote_inventario,
                                bodega,
                                item
                            }) => {
                                return {
                                    lote_inventario_id,
                                    lote_inventario,
                                    bodega_id,
                                    bodega,
                                    ...item
                                }
                            }
                        )
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        assignCliente(cliente_id, pc, last_vendedor) {
            if (cliente_id) {
                const cliente = this.clientes.find((c) => c.id === cliente_id)
                const { id, tercero_id, tercero, plazo_credito, vendedores } = cliente

                const {
                    declarante,
                    agente_retencion_fuente,
                    regimen_simple_tributacion,
                    gran_contribuyente
                } = tercero

                const {
                    agente_retencion_fuente: arfEmpresa,
                    autoretenedor_fuente,
                    agente_retencion_iva,
                    gran_contribuyente: gcEmpresa
                } = this.getUltimaEmpresa

                this.retencionBaseData = Object.assign(this.retencionBaseData, {
                    tercero: {
                        declarante,
                        agente_retencion_fuente,
                        regimen_simple_tributacion,
                        gran_contribuyente
                    },
                    empresa: {
                        agente_retencion_fuente: arfEmpresa,
                        autoretenedor_fuente,
                        agente_retencion_iva,
                        gran_contribuyente: gcEmpresa
                    }
                })

                this.vendedores = [...vendedores]

                if (last_vendedor) {
                    this.replaceVendedor(last_vendedor)

                    this.factura_venta.vendedor_id = last_vendedor
                } else {
                    this.factura_venta.vendedor_id = this.vendedores.length > 0 ? this.vendedores[0].id : null
                }

                this.factura_venta.tercero_id = tercero_id
                this.$v.factura_venta.cliente_id.$model = id

                const products = this.factura_venta_productosCopy.map(p => {
                    let newProduct = null
                    if (p.tercero_id) {
                        newProduct = { ...p }
                    } else {
                        newProduct = { ...p, tercero_id }
                    }

                    return newProduct
                })

                this.factura_venta_productosCopy = products

                if (pc !== undefined && pc !== null && pc !== '') {
                    this.assignVencimiento(pc)
                } else {
                    this.assignVencimiento(plazo_credito)
                }
                // console.log(id);
            } else {
                this.factura_venta.cliente_id = null
                this.factura_venta.tercero_id = null
                this.factura_venta.vendedor_id = null
                this.assignVencimiento(0)
            }
        },
        replaceVendedor(last_vendedor) {
            const retrieve_vendedor = this.vendedores.find(v => v.id == last_vendedor)

            if (retrieve_vendedor) {
                const index = this.vendedores.indexOf(retrieve_vendedor)

                this.vendedores.splice(index, 1, { ...retrieve_vendedor, ...this.factura_venta.vendedor })
            } else {
                this.vendedores.push({ id: last_vendedor, ...this.factura_venta.vendedor })
            }
        },
        assignVencimiento(value) {
            const plazo = value ? Number(value) : 0

            this.$v.plazo_vencimiento.$model = plazo
            this.$v.devolucion_factura.vencimiento.$model = dateAddDays(
                this.devolucion_factura.fecha,
                plazo
            )

            // console.log(dateAddDays(this.devolucion_factura.fecha, plazo))

            if (
                !this.$route.params.devolucion_id &&
                !(this.factura_venta.formas_pago.length > 0)
            ) {
                // console.log(this.$route.params)
                // (this.devolucion_factura.factura_venta_id === null ||
                //     this.devolucion_factura.factura_venta_id ===
                //         undefined ||
                //     this.devolucion_factura.factura_venta_id === '')
                if (plazo === 0) {
                    this.devolucion_factura.formas_pago = []
                    this.addFormaPago()
                } else {
                    this.devolucion_factura.formas_pago = []
                }
                this.$v.devolucion_factura.formas_pago.$reset()
            }
        },
        onChangeFechaFactura() {
            if (this.factura_venta.cliente_id) {
                const cliente = this.clientes.find(
                    (c) => c.id === this.factura_venta.cliente_id
                )
                // console.log(cliente)
                const { plazo_credito } = cliente
                // console.log(plazo_credito)
                this.assignVencimiento(plazo_credito)
            }
        },
        getTotalRetefuente(productos) {
            const obj = calcRetefuente(productos, this.retencionBaseData)

            const {
                total_retefuente,
                total_reteiva,
                retencion_detail,
                reteiva_detail
            } = obj

            // console.log(reteiva_detail)

            const reteiva = Object.keys(reteiva_detail.gruposReteIva).map(
                (key) => {
                    return {
                        group: key,
                        value: currencyFormat(
                            Number(reteiva_detail.gruposReteIva[key])
                        )
                    }
                }
            )

            const retencion = Object.keys(retencion_detail.gruposRetencion).map(
                (key) => {
                    const valorGrupo =
                        (retencion_detail.gruposRetencion[key] * Number(key)) /
                        100
                    return {
                        group: key,
                        base_retencion: currencyFormat(
                            retencion_detail.retenciones[key].base_retencion
                        ),
                        value: currencyFormat(Number(valorGrupo))
                    }
                }
            )

            // console.log(retencion_detail, reteiva_detail)

            this.detalle_factura = {
                ...this.detalle_factura,
                retencion,
                reteiva,
                gruposReteIva: reteiva_detail.gruposReteIva,
                gruposRetencion: retencion_detail.gruposRetencion,
                porcentajeReteIva: reteiva_detail.porcentajeReteIva
            }

            this.total_retefuente = total_retefuente
            this.total_reteiva = total_reteiva
        },
        save() {
            if (this.devolucion_factura.factura_venta_id === null) {
                this.factura_venta_productosCopy.splice(0, 1)
                this.factura_venta.productos.splice(0, 1)
            }

            // console.log(this.factura_venta.productos)

            this.productsLengthError =
                this.factura_venta_productosCopy.length < 1
            let comparisonFormasPagoGranTotal = true

            if (
                this.plazo_vencimiento == 0 &&
                (this.devolucion_factura.factura_venta_id === null ||
                    this.devolucion_factura.factura_venta_id === undefined ||
                    this.devolucion_factura.factura_venta_id === '')
            ) {
                comparisonFormasPagoGranTotal =
                    this.formasPagoValue >= this.granTotal
            }

            const valid =
                !this.$v.factura_venta.$invalid &&
                !this.$v.plazo_vencimiento.$invalid &&
                !this.productsLengthError &&
                !this.$v.devolucion_factura.$invalid

            if (valid) {
                if (!comparisonFormasPagoGranTotal) {
                    this.$notify({
                        group: 'general',
                        title: 'Valor de la factura',
                        text: 'El valor total de las formas de pago no es mayor o igual al valor total de la factura',
                        ignoreDuplicates: true,
                        duration: 5000,
                        type: 'error'
                    })

                    if (this.devolucion_factura.factura_venta_id === null) {
                        this.addBlankProduct()
                    }
                } else {
                    const {
                        total,
                        descuento: total_descuento,
                        total_ipoconsumo
                    } = this.totalesFacturas

                    const { consecutivo } = this.devolucion_factura

                    const devolucion_factura = {
                        ...this.devolucion_factura,
                        consecutivo: {
                            ...consecutivo,
                            prefijo:
                                consecutivo.prefijo === 'Sin prefijo'
                                    ? ''
                                    : consecutivo.prefijo
                        }
                    }

                    let productos_factura = []

                    if (this.devolucion_factura.factura_venta_id === null) {
                        productos_factura = this.factura_venta_productosCopy.productos.map(
                            (p) => ({
                                ...p,
                                cliente_id: this.factura_venta.cliente_id
                            })
                        )
                    } else {
                        productos_factura = this.factura_venta_productosCopy
                    }

                    const {
                        gruposReteIva,
                        gruposRetencion,
                        porcentajeReteIva
                    } = this.detalle_factura

                    const factura_venta_extra_data = {
                        usuario_id: this.getUserData.id,
                        empresa_id: this.getUltimaEmpresa.id,
                        sede_id: this.getUltimaSede.id,
                        estado_documento_id: 1,
                        total,
                        gran_total: this.granTotal,
                        subtotal_neto: total - total_descuento,
                        subtotal: total,
                        total_descuento,
                        total_ipoconsumo,
                        total_retefuente: this.total_retefuente,
                        total_reteiva: this.total_reteiva,
                        cambio: this.cambio,
                        factura_venta_id:
                            this.devolucion_factura.factura_venta_id,
                        tipo_devolucion_id: this.tipo_devolucion_id,
                        configuracion_impuestos: {
                            retefuente: this.total_retefuente,
                            reteiva: this.total_reteiva,
                            productos: productos_factura,
                            gruposReteIva,
                            gruposRetencion,
                            porcentajeReteIva
                        },
                        ...devolucion_factura
                    }

                    const factura_venta = {
                        ...this.factura_venta,
                        ...factura_venta_extra_data,
                        productos: productos_factura,
                    }

                    // console.log(factura_venta, this.factura_venta_productosCopy)

                    this.isLoading = true
                    const p_opc = this.factura_venta.id ? 'UPDATE' : 'INSERT'
                    API.POST({
                        url: 'administracion/devolucion-factura/crud',
                        data: {
                            p_datajson: {
                                ...factura_venta
                            },
                            p_opc,
                            p_auditoriajson: this.auditoriajson
                        }
                    })
                        .then((response) => {
                            const {
                                data: {
                                    dato: { document: documentBase64 },
                                    status: {
                                        status,
                                        mensaje,
                                        mensaje_exception
                                    }
                                }
                            } = response
                            this.isLoading = false
                            if (status === 'ok' || status === 'warn') {
                                this.isSaved = true
                                if (
                                    this.factura_venta.consecutivo_factura
                                        .auto_print &&
                                    documentBase64
                                ) {
                                    const base64 = documentBase64.replace(
                                        'data:application/pdf;base64,',
                                        ''
                                    )
                                    print({
                                        printable: base64,
                                        type: 'pdf',
                                        base64: true
                                    })
                                }

                                if (p_opc === 'INSERT') {
                                    consecutivo.secuencia += 1
                                    this.factura_venta = defaultFacturaVenta()
                                    this.factura_venta_productosCopy = []
                                    this.total_retefuente = 0
                                    this.total_reteiva = 0
                                    this.detalle_factura = {}
                                    this.devolucion_factura =
                                        defaultDevolucion()
                                    this.$v.devolucion_factura.$reset()
                                    this.$v.factura_venta.$reset()
                                    this.assignCliente(null)

                                    this.setConsecutivo(consecutivo)
                                    const factura_id_value =
                                        document.getElementById(
                                            'factura_venta_id'
                                        )
                                    factura_id_value.value = ''

                                    // this.$notify({
                                    //     group: 'general',
                                    //     title: 'Devolución de factura Creada!',
                                    //     text: `La devolución de la factura de venta se ha creado correctamente`,
                                    //     ignoreDuplicates: true,
                                    //     duration: 5000,
                                    //     type: 'success'
                                    // })

                                    this.addBlankProduct()

                                    this.$notify({
                                        group: 'general',
                                        title:
                                            status === 'ok'
                                                ? 'Devolución de factura Creada!'
                                                : 'Advertencia',
                                        text:
                                            status === 'ok'
                                                ? `La devolución de la factura de venta se ha creado correctamente`
                                                : mensaje_exception || mensaje,
                                        ignoreDuplicates: true,
                                        duration: 5000,
                                        type:
                                            status === 'ok' ? 'success' : 'warn'
                                    })
                                } else {
                                    this.back()
                                }
                            } else {
                                this.$bvModal
                                    .msgBoxOk(
                                        mensaje ||
                                            mensaje_exception ||
                                            'Ha ocurrido un error!',
                                        {
                                            title: 'Error!',
                                            size: 'sm',
                                            buttonSize: 'sm',
                                            okVariant: 'error',
                                            okTitle: 'Aceptar',
                                            cancelTitle: 'Cancelar',
                                            footerClass: 'p-2',
                                            hideHeaderClose: false,
                                            centered: true,
                                            headerBgVariant: 'error',
                                            headerClass: 'modal-header'
                                        }
                                    )
                                    .then(() => {
                                        // console.log(value);
                                    })
                                    .catch((err) => {
                                        console.log(err)
                                    })
                            }
                        })
                        .catch((error) => {
                            this.isLoading = false
                            const { message } = error
                            this.$bvModal
                                .msgBoxOk(message || 'Ha ocurrido un error!', {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                })
                                .then(() => {
                                    // console.log(value);
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        })
                }
            } else {
                if (this.devolucion_factura.factura_venta_id === null) {
                    this.addBlankProduct()
                }
                this.$v.factura_venta.$touch()
                this.$v.factura_venta_productosCopy.$touch()
                this.$v.devolucion_factura.$touch()
            }
        },
        getLabelCliente(item) {
            // console.log(item)
            const {
                tercero: {
                    tipo_documento_id,
                    nombre1,
                    nombre2,
                    apellido1,
                    apellido2,
                    razon_social,
                    n_documento
                },
                nombre_comercial
            } = item
            let label = ''

            label = nombre_comercial

            if (tipo_documento_id == 13) {
                label = `${label} - ${nombre1}${
                    nombre2 && ` ${nombre2}`
                } ${apellido1}${apellido2 && ` ${apellido2}`}`
            } else {
                label = `${label} - ${razon_social}`
            }

            label = `${label} - ${n_documento}`

            return label
        },
        getLabelItem(item) {
            const {
                descripcion,
                referencia,
                codigo_barra,
                contenido: existencia,
                precio_base_venta
            } = item
            let label = ''

            label = `${descripcion} - ${referencia} - ${codigo_barra} - ${existencia} - ${currencyFormat(
                precio_base_venta
            )}`

            return label
        },
        confirmResendNc({ id, numero }) {
            this.$bvModal
                .msgBoxConfirm(
                    `Esta seguro que desea reenviar la devolución ${numero}", a devolución electrónica?`,
                    {
                        title: 'Advertencia!',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: 'Continuar',
                        cancelTitle: 'Cancelar',
                        footerClass: 'p-2 d-flex justify-content-between',
                        hideHeaderClose: false,
                        centered: true,
                        headerBgVariant: 'danger',
                        headerClass: 'modal-header'
                    }
                )
                .then((value) => {
                    if (value) {
                        this.resendNc(id)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        resendNc(id) {
            this.isLoading = true
            API.POST({
                url: 'administracion/devolucion-factura/reenviar-nc',
                data: {
                    p_datajson: {
                        id
                    },
                    p_opc: 'FIND_BY_ID',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    this.isLoading = false
                    const {
                        data: {
                            // dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    // console.log(response)
                    if (status === 'ok') {
                        this.$notify({
                            group: 'general',
                            title: 'Reenvio exitoso',
                            text: 'La devolución electrónica se ha rrenviado exitosamente',
                            ignoreDuplicates: true,
                            duration: 5000,
                            type: 'success'
                        })
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                mensaje_exception ||
                                'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    this.isLoading = false
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        printDevolucionFactura(devolucion_id) {
            this.isLoading = true
            API.POST({
                url: 'administracion/devolucion-factura/imprimir',
                data: {
                    p_datajson: {
                        id: devolucion_id
                    },
                    p_opc: 'FIND_BY_ID',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    this.isLoading = false
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        // console.log(dato)
                        const base64 = dato.replace(
                            'data:application/pdf;base64,',
                            ''
                        )
                        print({
                            printable: base64,
                            type: 'pdf',
                            base64: true
                        })
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje_exception ||
                                mensaje ||
                                'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    this.isLoading = false
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        sendSingleFactura(factura_id) {
            this.singleEmailParams = { id: factura_id }
            this.$bvModal.show('email-modal-single')
        },
        getEmpresaEmails() {
            API.POST({
                url: 'cartero/config-mail/query',
                data: {
                    p_datajson: {
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'FIND_BY_EMPRESA',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.configEmail = dato[0]
                    } else if (dato.length === 0 && status !== 'err') {
                        this.$bvModal
                            .msgBoxConfirm(
                                'Esta empresa no posee configuración de correo para reportes o envío de documentos por email, desea crear una configuración de correo para esta empresa?',
                                {
                                    title: 'Advertencia!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'warning',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'warning',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then((value) => {
                                if (value) {
                                    this.$router
                                        .push({
                                            path: '/home/general/generales/configuracion-email/crear-configuracion-email'
                                        })
                                        .catch((error) => error)
                                }
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                mensaje_exception ||
                                'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getAvailableFields() {
            if (this.permisos.includes('factura-venta-query-export')) {
                API.POST({
                    url: 'report/available-fields',
                    data: {
                        p_datajson: {
                            modulo: this.moduleName
                        },
                        p_opc: 'GET_FIELD',
                        p_auditoriajson: this.auditoriajson
                    }
                })
                    .then((response) => {
                        const {
                            data: {
                                dato,
                                status: { status, mensaje, mensaje_exception }
                            }
                        } = response
                        if (status === 'ok') {
                            // console.log(dato)
                            this.fieldsAvailables = dato
                        } else {
                            this.$bvModal
                                .msgBoxOk(
                                    mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                    {
                                        title: 'Error!',
                                        size: 'sm',
                                        buttonSize: 'sm',
                                        okVariant: 'error',
                                        okTitle: 'Aceptar',
                                        cancelTitle: 'Cancelar',
                                        footerClass: 'p-2',
                                        hideHeaderClose: false,
                                        centered: true,
                                        headerBgVariant: 'error',
                                        headerClass: 'modal-header'
                                    }
                                )
                                .then(() => {
                                    // console.log(value);
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                    })
                    .catch((error) => {
                        const { message } = error
                        this.$bvModal
                            .msgBoxOk(message || 'Ha ocurrido un error!', {
                                title: 'Error!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'error',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'error',
                                headerClass: 'modal-header',
                                noStacking: true
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    })
            }
        },
        back() {
            this.$router.back()
        },
        validateState(key) {
            // console.log(key)
            const { $dirty, $error } = this.$v.factura_venta[key]
            return $dirty ? !$error : null
        },
        validateStateSingle(key) {
            const { $dirty, $error } = this.$v[key]
            return $dirty ? !$error : null
        },
        validateStateDevolucion(key) {
            const { $dirty, $error } = this.$v.devolucion_factura[key]
            return $dirty ? !$error : null
        },
        validateStateProducts(index, key) {
            const { $dirty, $error } =
                this.$v.factura_venta_productosCopy.$each[index][key]
            return $dirty ? !$error : null
        },
        validateStateFormasPago(index, key) {
            const { $dirty, $error } =
                this.$v.devolucion_factura.formas_pago.$each[index][key]
            return $dirty ? !$error : null
        },
        openItemsTable() {
            this.$refs.itemsButton.click()
        }
    },
    computed: {
        granTotal() {
            const {
                subtotal_neto: subtotal,
                total_ipoconsumo: impoconsumo,
                total_iva: iva,
                descuento
            } = this.totalesFacturas

            const total =
                subtotal +
                iva +
                impoconsumo -
                (descuento + this.total_retefuente + this.total_reteiva)

            return total
        },
        totalesFacturas() {
            const fields = [
                'total',
                'descuento',
                'total_ipoconsumo',
                'total_iva',
                'subtotal_neto'
            ]
            const productos = this.factura_venta.productos

            // console.log(JSON.parse(JSON.stringify(productos[0])))

            const totales = {}

            fields.forEach((field) => {
                const arrayTotal = productos.map((producto) => producto[field])

                const total = arrayTotal.reduce((a, b) => Math.round(a + b), 0)

                totales[field] = total
                // if (field === 'total_ipoconsumo') {
                //     console.log(totales)
                // }
            })

            return totales
        },
        formasPagoValue() {
            if (this.$v.plazo_vencimiento.$model === 0) {
                return this.devolucion_factura.formas_pago
                    .map((f) => f.value)
                    .reduce((a, b) => Number(a) + Number(b), 0)
            } else {
                return 0
            }
        },
        cambio() {
            if (this.formasPagoValue > this.granTotal) {
                const value = this.formasPagoValue - this.granTotal
                return value
            } else {
                return 0
            }
        },
        keymap() {
            if (
                navigator.platform.toLowerCase().includes('mac') ||
                navigator.platform.toLowerCase().includes('macintosh')
            ) {
                return {
                    'command+shift+s': this.read_only ? () => {} : this.save,
                    esc: this.back,
                    'command+b': this.read_only ? () => {} : this.openItemsTable
                }
            } else if (
                navigator.platform.toLowerCase().includes('win') ||
                navigator.platform.toLowerCase().includes('windows')
            ) {
                return {
                    'ctrl+shift+s': this.read_only ? () => {} : this.save,
                    esc: this.back,
                    'ctrl+b': this.read_only ? () => {} : this.openItemsTable
                }
            } else {
                return {}
            }
        },
        permisos() {
            return this.getPermisos.permisos_authorities
        },
        auditoriajson() {
            const json = { ...this.getAuditoriaJson, modulo_id: 41 }
            return json
        },
        ...mapGetters('usuario', ['getUserData', 'getAuditoriaJson', 'getPermisos']),
        ...mapGetters('empresas', ['getUltimaEmpresa']),
        ...mapGetters('sedes', ['getUltimaSede'])
    }
}
</script>

<style></style>
